import React from 'react';

function WorldWeb({ color }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.806"
      height="18.806"
      viewBox="0 0 18.806 18.806"
    >
      <path
        fill={color || '#4d5153'}
        d="M11.394 2a9.4 9.4 0 109.413 9.4A9.4 9.4 0 0011.394 2zm6.516 5.642h-2.774a14.715 14.715 0 00-1.3-3.348 7.55 7.55 0 014.074 3.348zM11.4 3.918a13.246 13.246 0 011.8 3.724H9.607A13.246 13.246 0 0111.4 3.918zm-7.275 9.366a7.355 7.355 0 010-3.761H7.3a15.53 15.53 0 00-.128 1.877 15.53 15.53 0 00.128 1.884zm.775 1.881h2.77a14.715 14.715 0 001.3 3.348 7.51 7.51 0 01-4.07-3.348zm2.77-7.523H4.9a7.51 7.51 0 014.068-3.348 14.715 14.715 0 00-1.3 3.348zm3.73 11.246a13.246 13.246 0 01-1.8-3.724h3.6a13.246 13.246 0 01-1.8 3.724zm2.2-5.6H9.2a13.835 13.835 0 01-.15-1.881 13.715 13.715 0 01.15-1.884h4.4a13.715 13.715 0 01.15 1.881 13.835 13.835 0 01-.15 1.88zm.235 5.228a14.715 14.715 0 001.3-3.348h2.775a7.55 7.55 0 01-4.071 3.344zm1.665-5.232a15.53 15.53 0 00.132-1.881 15.53 15.53 0 00-.132-1.88h3.178a7.355 7.355 0 010 3.761z"
        data-name="Path 2834"
        transform="translate(-2 -2)"
      ></path>
    </svg>
  );
}

export default WorldWeb;
